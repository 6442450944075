import React from 'react';
import styled from 'styled-components';

import './TextBox.scss'
import PropTypes from "prop-types";

const TextBox = ({bgColor, children, modifiers, href}) => {
  let TextBoxElement;
  if (href) {
    TextBoxElement = styled.a`
      background-color: ${bgColor};
    `;
  } else {
    TextBoxElement = styled.div`
      background-color: ${bgColor};
    `;
  }

  const attrs = {};
  if (href) {
    attrs.href = href;
    attrs.target = "_blank";
  }

  return (
    <TextBoxElement {...attrs} className={`text-box ${modifiers.map(item => "text-box--" + item).join(' ')}`}>
      {children}
    </TextBoxElement>
  )
};

TextBox.defaultProps = {
  modifiers: [],
  href: ''
};

TextBox.propTypes = {
  modifiers: PropTypes.array,
  href: PropTypes.string
};

export default TextBox;
