import React from 'react';

import "./IconBoxes.scss";
import IconBox from "./IconBox";

const IconBoxes = ({elements}) => {
  const icons = elements.map(({title, icon}) => <IconBox key={title} title={title} icon={icon} />);
  return (
    <div className="icon-boxes">
      {icons}
    </div>
  )
};

export default IconBoxes;
