import React from 'react';
import withLayout from '../../layout';

import { StaticQuery, graphql } from "gatsby"
import "./layout.scss";

import Image from "../../components/layout/Sections/Image";
import ContactSlide from "../../components/Contact/ContactSlide";

import {FormattedHTMLMessage, injectIntl} from "react-intl";
import List from '../../components/Typography/List';
import TextBox from '../../components/Typography/TextBox';
import TextBardziej from '../../components/Typography/TextBardziej';
import IconBoxes from '../../components/IconBoxes';
import Accordion from '../../components/UI/Accordion/Accordion';

import Icon1 from "../../shared/icons/kompetencje/icon1.svg";
import Icon2 from "../../shared/icons/kompetencje/icon2.svg";
import Icon3 from "../../shared/icons/kompetencje/icon3.svg";
import Icon5 from "../../shared/icons/kompetencje/icon5.svg";
import Icon6 from "../../shared/icons/kompetencje/icon6.svg";
import Icon7 from "../../shared/icons/kompetencje/icon7.svg";
import Icon8 from "../../shared/icons/kompetencje/icon8.svg";
import Icon9 from "../../shared/icons/kompetencje/icon9.svg";
import EqualIcon from "../../shared/icons/equal.svg";
import ImageText from "../../images/kompetencje/technology.jpg"
import Link from "../../components/Link";
import Heading from "../../components/Typography/Heading";
import SectionColumn from "../../components/layout/Sections/SectionColumn";
import TextContent from "../../components/Typography/TextContent";
import Spacer from "../../components/layout/Helpers/Spacer";
import Section from "../../components/layout/Sections/Section";
import ImageMask from "../../components/layout/Images/ImageMask";
import StandardPageComponent from "../../components/Base/StandardPageComponent";

class Websites extends StandardPageComponent {

  render() {

    const listItems = [
      "Atrakcyjny, często oryginalny wygląd, przyciąga uwagę użytkowników. Intuicyjna, prosta nawigacja ułatwia znalezienie potrzebnych rzeczy i dokonanie zakupu.",
      "Dostosowanie do najnowszych standardów gwarantuje, że strona będzie wyglądać równie dobrze na każdym urządzeniu i każdej przeglądarce.",
      "To właśnie dzięki temu oraz sporej dawce doświadczenia i ludziom z pasją,"
    ];

    const iconsLeft = [
      {
        "title": "Nowoczesny, estetyczny i responsywny wygląd",
        "icon": <Icon1 />
      },
      {
        "title": "Intuicyjna i funkcjonalna architektura",
        "icon": <Icon2 />
      },
      {
        "title": "Szybkie ładowanie i wygodny wybór elementów",
        "icon": <Icon3 />
      },
      {
        "title": "Wartościowe i oryginalne treści",
        "icon": <Icon5 />
      }
    ];

    const iconsRight = [
      {
        "title": "Wizytówka firmy",
        "icon": <Icon6 />
      },
      {
        "title": "Sprzedaż produktów/usług",
        "icon": <Icon7 />
      },
      {
        "title": "Budowanie historii marki",
        "icon": <Icon8 />
      },
      {
        "title": "Rozwój firmy",
        "icon": <Icon9 />
      }
    ];

    return (
      <>
        <StaticQuery
          query={graphql`
            query {
              goodbg: file(relativePath: { eq: "kompetencje/bg-good.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              bgtext: file(relativePath: { eq: "kompetencje/bg-text.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={data => (
            <div className="page page--small-space page--static page--www">

              <Section modifiers={['text', 'columns', 'padding-x']} modifiersLayout={['padding']}>
                <SectionColumn>
                  <TextContent modifiers={['light-big']}>
                    <h1>
                      <FormattedHTMLMessage id="competences.websites.text1" />
                    </h1>
                  </TextContent>
                </SectionColumn>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id="competences.websites.text2" />
                    <TextContent contentTag="span" modifiers={['normal', 'bold']} classes={['text-special']}>
                      <strong>
                        <FormattedHTMLMessage id="competences.websites.text3" />
                      </strong>
                    </TextContent>
                  </TextContent>
                  <Spacer />
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id="competences.websites.text4" />
                  </TextContent>
                  <Spacer modifiers={['small']} />
                  <TextContent modifiers={['normal', 'bold']}>
                    <strong>
                      <FormattedHTMLMessage id="competences.websites.text5" />
                    </strong>
                  </TextContent>
                </SectionColumn>
              </Section>

              <Image image={data.goodbg.childImageSharp.fluid} />

              <Section modifiers={['text-bardziej', 'columns', 'padding-x']} modifiersLayout={['padding', 'top']}>
                <SectionColumn>
                  <TextBardziej>
                    <h2>
                      <strong>
                        <TextContent modifiers={['inherit']}>
                          <FormattedHTMLMessage id="competences.websites.text6" />
                        </TextContent>
                        <Spacer contentTag="span" modifiers={['empty']}/>
                        <FormattedHTMLMessage id="competences.websites.text7" />
                      </strong>
                    </h2>
                  </TextBardziej>
                </SectionColumn>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id='competences.websites.text8' />
                  </TextContent>
                </SectionColumn>
              </Section>

              <Section modifiers={['text', 'columns', 'padding-x', 'border-top']} modifiersLayout={['padding']}>
                <SectionColumn>
                  <TextContent modifiers={['light-big']}>
                    <h2>
                      <FormattedHTMLMessage id="competences.websites.text9" />
                    </h2>
                  </TextContent>
                </SectionColumn>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id="competences.websites.text10" />
                  </TextContent>
                  <Spacer modifiers={['small']} />
                  <TextContent modifiers={['normal', 'bold']}>
                    <strong>
                      <h3>
                        <FormattedHTMLMessage id="competences.websites.text11" />
                      </h3>
                    </strong>
                  </TextContent>
                </SectionColumn>
              </Section>

              <Section modifiers={['text', 'columns', 'padding-x']} modifiersLayout={['padding']}>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <List items={listItems} />
                  </TextContent>
                  <TextContent modifiers={['normal', 'bold', 'padding-left', 'left']}>
                    <h3>
                      <strong><FormattedHTMLMessage id="competences.websites.text12" /></strong>
                    </h3>
                  </TextContent>
                </SectionColumn>
                <SectionColumn>
                  <TextBox bgColor="#f07e00">
                    <TextContent modifiers={['small', 'white']}>
                      <FormattedHTMLMessage id="competences.websites.text13"/>
                    </TextContent>
                    <Spacer modifiers={['small']} />
                    <TextContent modifiers={['normal', 'bold', 'white']}>
                      <strong><FormattedHTMLMessage id="competences.websites.text14" /></strong>
                    </TextContent>
                    <Spacer modifiers={['small']} />
                    <TextContent modifiers={['small', 'white']}>
                     <FormattedHTMLMessage id="competences.websites.text15" />
                    </TextContent>
                    <Spacer modifiers={['small']} />
                    <TextContent modifiers={['normal', 'bold', 'white']}>
                      <strong><FormattedHTMLMessage id="competences.websites.text16" /></strong>
                    </TextContent>
                    <Spacer modifiers={['small']} />
                    <TextContent modifiers={['small', 'white']}>
                      <FormattedHTMLMessage id="competences.websites.text17" />
                    </TextContent>
                  </TextBox>
                </SectionColumn>
              </Section>

              <section className="section section--icons section--columns section--columns-force section--padding-x section--padding-y-small">
                <div className="wrapper">
                  <div className="section__layout section__layout--top section__layout--padding">
                    <div className="section__column">
                      <IconBoxes elements={iconsLeft} />
                      <EqualIcon className="equal-icon" />
                    </div>
                    <div className="section__column">
                      <IconBoxes elements={iconsRight} />
                    </div>
                  </div>
                </div>
              </section>

              <Image image={data.bgtext.childImageSharp.fluid}
                     text="competences.websites.text18"
              />

              <Section modifiers={['text', 'columns', 'padding-x', 'padding-y-small']} modifiersLayout={['top', 'padding']}>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id="competences.websites.text19" />
                    <Spacer modifiers={['small']} />
                    <TextContent modifiers={['normal']}>
                      <FormattedHTMLMessage id="competences.websites.text20" />
                    </TextContent>
                  </TextContent>
                  <Spacer modifiers={['small']} />
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id="competences.websites.text21" />

                    <Link to="/#projekty"><FormattedHTMLMessage id="competences.websites.text22" /></Link><FormattedHTMLMessage id="competences.websites.text23" />
                  </TextContent>
                </SectionColumn>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id="competences.websites.text24" /><Link to="/bardziej/"><FormattedHTMLMessage id="competences.websites.text25" /></Link>
                    <FormattedHTMLMessage id="competences.websites.text26" />
                    <Spacer modifiers={['small']} />
                    <TextContent modifiers={['normal', 'bold']}>
                      <strong><FormattedHTMLMessage id="competences.websites.text27" /></strong>
                    </TextContent>
                  </TextContent>
                </SectionColumn>
              </Section>

              <Section modifiers={['image-text', 'columns', 'padding-x', 'border-top']} modifiersLayout={['stretch', 'padding']}>
                <SectionColumn>
                  <TextContent modifiers={['bigger']}>
                    <ImageMask bgUrl={ImageText}>
                      <TextContent modifiers={['bigger-2', 'bold']}>
                        <h2>
                          <FormattedHTMLMessage id="competences.websites.text28" />
                        </h2>
                      </TextContent>
                    </ImageMask>
                  </TextContent>
                </SectionColumn>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id="competences.websites.text29" />
                    <Spacer modifiers={['small']} />
                    <TextContent modifiers={['normal']}>
                      <h3>
                        <FormattedHTMLMessage id="competences.websites.text30" />
                      </h3>
                    </TextContent>
                  </TextContent>
                </SectionColumn>
              </Section>

              <Section modifiers={['text-bardziej', 'columns', 'padding-x', 'padding-y-more-bottom']} modifiersLayout={['padding', 'top']}>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <p>
                      <FormattedHTMLMessage id="competences.websites.text31" />
                      <TextContent contentTag="span" modifiers={['normal', 'block']}>
                          <strong><FormattedHTMLMessage id="competences.websites.text32" /></strong>
                      </TextContent>

                      <FormattedHTMLMessage id="competences.websites.text33" />
                    </p>

                    <FormattedHTMLMessage id="competences.websites.text34" />
                  </TextContent>
                </SectionColumn>
                <SectionColumn modifiers={['no-padding-top']}>
                  <TextBardziej modifiers={['left', 'bold']} modifiersImage={['top']}>
                    <h2>
                      <FormattedHTMLMessage id="competences.websites.text35" />
                    </h2>
                  </TextBardziej>
                </SectionColumn>
              </Section>

              <Section modifiers={['padding-x', 'border-top']} modifiersLayout={['padding']}>
                <SectionColumn>
                  <Heading titleTag="h2" title="competences.websites.text36" size="section" />
                  <Accordion labelTag="h3">
                    <div label="Co powinno znaleźć się na mojej stronie internetowej?">
                      <div>
                        <p>
                          Wszystko zależy od rodzaju strony i specyfiki Twojej marki. Są jednak pewne elementy,
                          o które warto zadbać zawsze. Pośród nich należy wymienić ofertę firmy (produkty/usługi -
                          jeśli są bardziej zróżnicowane, powinny mieć osobne strony/podstrony), dział „o nas”
                          (by przedstawić kim jesteśmy i czym się zajmujemy) oraz zakładkę „kontakt” (nikt nie
                          lubi długo szukać namiaru na firmę). Na stronie w widocznym miejscu należy też umieścić
                          odnośniki do profili firmy w mediach społecznościowych (o ile ta je posiada): Facebooka,
                          Twittera czy Instagrama.
                        </p>
                        <p>
                          Warto rozważyć w strategii marki również umieszczenie na stronie głównej zakładki z blogiem,
                          gdzie regularnie będą ukazywać się artykuły - dobrze zaplanowane i przygotowane treści pozytywnie
                          wpłyną na pozycję strony www w wynikach wyszukiwarek.
                        </p>
                      </div>
                    </div>
                    <div label="W jaki sposób mogę sprawdzić, czy moja strona internetowa spełnia wymogi wyszukiwarek i użytkowników?">
                      <div>
                        <p>
                          Dokładne, kompleksowe sprawdzenie jakości Twojej strony, wymaga specjalistycznej wiedzy, znajomości
                          algorytmów wyszukiwarek oraz wykorzystania odpowiednich narzędzi. Jeśli potrzebujesz szczegółowej
                          analizy strony internetowej – najlepiej zlecić profesjonalny audyt agencji. Dzięki temu nie tylko
                          wykryjesz problemy na swojej stronie, ale również dowiesz się, co zrobić, by je zniwelować.
                          <br/>
                          Działając samodzielnie wciąż możesz jednak sprawdzić podstawowe rzeczy. Możesz na przykład
                          przeprowadzić test optymalizacji mobilnej (Google udostępnia taką możliwość), sprawdzić szybkość
                          ładowania strony (można to zrobić za pomocą darmowych narzędzi), czy poprosić kilka osób, aby oceniły,
                          czy mogą łatwo znaleźć na stronie wszystko, czego potrzebują. Takie testy niestety nie zdiagnozują
                          wszystkich problemów, które mogą negatywnie rzutować na pozycję strony www w wynikach wyszukiwarek,
                          dlatego jeżeli zależy Ci na kompleksowej diagnozie – warto powierzyć to zadanie profesjonalistom.
                        </p>
                      </div>
                    </div>
                    <div label="Co decyduje o popularności strony internetowej?">
                      <div>
                        <p>
                          Na popularność strony internetowej wpływa bardzo wiele czynników. Większość z nich można podzielić
                          na cztery najważniejsze grupy: wygląd i funkcjonalność strony, popularność danej marki, poziom
                          dostosowania strony do wymagań internautów i wyszukiwarek oraz prowadzony marketing internetowy.
                          <br/>
                          Jeśli nasza marka jest już powszechnie kojarzona, istnieje większa szansa na to, że ktoś wejdzie
                          na stronę, poszukując informacji konkretnie o marce. Im strona atrakcyjniejsza i bardziej przyjazna
                          użytkownikom, tym chętniej będą oni na nią wracać. Dostosowanie do algorytmów wyszukiwarek z kolei
                          wpływa na wyższe pozycje w wyszukiwaniach - a internauci zazwyczaj wchodzą na strony, które znajdą
                          jako pierwsze. Umiejętnie prowadzony marketing internetowy dodatkowo generuje ruch na stronie
                          (a i może wpłynąć na pozycje wyszukiwań na konkretne słowa kluczowe).
                        </p>
                      </div>
                    </div>
                    <div label="Jakie informacje należy dostarczyć, zlecając projekt strony internetowej?">
                      <div>
                        <p>Zlecając projekt strony zewnętrznemu wykonawcy, szczególnie zaś agencji, powinieneś dostarczyć
                          podstawowe informacje na temat profilu firmy, wymagań odnośnie strony i jej funkcji, oczekiwania
                          wobec oferty oraz założenia głównej grupy docelowej. Musisz wskazać, jaki rodzaj strony internetowej
                          Cię interesuje (serwis firmowy, produktowy, społecznościowy, landing page, portal informacyjno -
                          ogłoszeniowy, e-commerce, etc.).</p>
                        <p>Kolejne istotne informacje, poprzedzające wykonanie strony internetowej, to główne cele, jakim ma
                          służyć witryna; elementy, jakie mają się na niej znaleźć; cechy wyróżniające markę i założenia budowy
                          jej wizerunku. Warto też przygotować swoje wymagania i możliwości - wcześniej zasygnalizowany budżet
                          oraz wymagany termin realizacji zadania, pozwoli wykonawcom lepiej dostosować swoją ofertę i realnie
                          zaplanować prace. Jeśli marka posiada identyfikację wizualną, z którą strona powinna być spójna
                          (na przykład kolorystycznie) również należy to uwzględnić. </p>
                        <p>To wszystko jest jednak zaledwie początkiem – tworzenie strony www dla firm to poważne przedsięwzięcie,
                          w związku z czym przedstawiciele agencji mogą mieć jeszcze wiele dodatkowych pytań, na pewno będą chcieli
                          także omówić z Tobą niektóre pomysły i sugestie. Pamiętaj, że takie podejście ma na celu stworzenie strony,
                          która jak najlepiej będzie służyć Twojej firmie.</p>
                      </div>
                    </div>
                    <div label="Co to jest wersja RWD?">
                      <div>
                        <p>RWD, czyli responsive web design, to projektowanie strony internetowej w taki sposób, aby była
                          “responsywna”, a więc dostosowywała się do urządzeń i przeglądarek, z których korzysta użytkownik.
                          Dzięki temu odbiorca na każdym urządzeniu (desktop, tablet, mobile - z różnymi rozdzielczościami)
                          może swobodnie korzystać ze strony, bez niepotrzebnego ładowania się dodatkowych treści, ucinania
                          elementów i bez wielu innych błędów, które mogłyby go skutecznie zniechęcić do firmy. Znaczenie RWD
                          wzrasta w ostatnich latach wraz z rosnącą popularnością urządzeń mobilnych, a budowanie stron www
                          zgodnie z ich wymaganiami jest dziś standardem.</p>
                      </div>
                    </div>
                    <div label="Czym jest CMS?">
                      <div>
                        <p>CMS (Content Managment System), oznaczający w języku polskim System Zarządzania Treścią. Odpowiada on
                          za możliwość dokonywania zmian na stworzonej stronie www: zarządzanie nią, rozbudowę oraz dodawanie
                          treści i innych elementów. Jest on szczególnym ułatwieniem dla osób nieposiadających wiedzy na temat
                          programowania - w intuicyjny i zorganizowany sposób, pozwala użytkownikom (administrator strony,
                          pracownicy firmy, którym przekażemy dostępy do strony, itp.) edytować zawartość witryny. To dzięki
                          niemu istnieje możliwość na przykład dodania wpisu na bloga podpiętego pod stronę, zmiany opisu produktów,
                          wrzucenie aktualności czy podmiana grafiki na sliderze.
                          <br/>
                          Jednym z najpopularniejszych obecnie, gotowych systemów CMS jest WordPress. Przy korzystaniu z niego
                          mamy możliwość stosować gotowe rozwiązania w postaci wtyczek i szablonów, które oferuje platforma. Jest
                          to jednak system oparty na gotowych rozwiązaniach, które nie zawsze sprawdzą się dla bardziej wymagających
                          stron. Warto rozważyć wtedy przygotowanie autorskiego rozwiązania – tworzenie stron internetowych
                          CMS w oparciu o dedykowany system, co oferuje większe możliwości dostosowania do indywidualnych potrzeb,
                          budowę zaawansowanych funkcji i odpowiedź na specyficzne potrzeby zarządzania treścią w witrynie.</p>
                      </div>
                    </div>
                    <div label="Do czego potrzebny jest hosting?">
                      <div>
                        <p>Hosting to udostępnianie miejsca na dysku twardym, które zostaje zarezerwowane specjalnie pod działanie
                          strony www, przechowywanie danych, pojemność i funkcjonowanie skrzynki e-mailowej, etc. Takie miejsce
                          określamy mianem serwera, na którym umieszczona zostaje strona internetowa. Odpowiednia kontrola serwera,
                          jego prędkość i pojemność, stały dostęp do Internetu, czy nawet - co oczywiste - jego nieprzerwane
                          funkcjonowanie, zapewnia nieustanne działanie naszej strony www.</p>
                      </div>
                    </div>
                    <div label="Czym jest UX i dlaczego potrzebuje go Twoja strona www?">
                      <div>
                        <p>
                          UX, czyli user experience, to wszystkie doświadczenia użytkownika i jego wrażenia z korzystania z produktu
                          interaktywnego - na przykład strony internetowej. To właśnie odpowiednie zaprojektowanie strony
                          internetowej pod UX design, często decyduje o tym, jakie odczucia wywołuje w użytkowniku nasza witryna,
                          czy taka osoba wróci na naszą stronę www, w jaki sposób będzie postrzegać markę, do której strona należy
                          i czy zakupi promowane na stronie produkty/usługi. Projektując witrynę dla firmy, należy dążyć do poprawy
                          doświadczeń użytkownika i jego interakcji na linii człowiek-komputer. Warto zatem, aby strona www została
                          stworzona w taki sposób, aby korzystanie z niej było przyjazne i intuicyjne. Dobrze zaprojektowana,
                          zorientowana na użytkownika strona internetowa, wpływa na potencjał zwiększenia ruchu na niej oraz
                          większej liczby konwersji.
                        </p>
                      </div>
                    </div>
                  </Accordion>
                </SectionColumn>
              </Section>

            </div>
          )}
        />
        <ContactSlide standalone={true} titleTag="h2" />
      </>
    )
  }
};

const customProps = {
  localeKey: 'competences',
};

export default withLayout(customProps)(injectIntl(Websites));
