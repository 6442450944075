import React from 'react';

import "./IconBox.scss";
import PlusIcon from "../shared/icons/plus.svg";

const IconBox = ({title, icon}) => (
  <div className={`icon-title-box${!title ? ' icon-title-box--empty' : ''}`}>
    <div className="icon-title-box__content">
      <div className="icon-title-box__icon">{icon}</div>
      <div className="icon-title-box__title">{title}</div>
    </div>
    <div className="plus-icon">
      <PlusIcon />
    </div>
  </div>
);

export default IconBox;
