import React, { Component } from 'react';
import PropTypes from 'prop-types';

import "./AccordionItem.scss";

import ArrowBottom from '../../../shared/icons/arrow-bottom.svg';
import ArrowTop from '../../../shared/icons/arrow-top.svg';

class AccordionItem extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label, labelTag: Tag },
    } = this;

    return (
      <div className={`accordion-item${isOpen ? ' accordion-item--active': ''}`}>
        <div onClick={onClick} className='accordion-item__head'>
          <Tag>{label}</Tag>
          <div>
            {!isOpen && <span className="arrow"><ArrowBottom/></span>}
            {isOpen && <span className="arrow"><ArrowTop/></span>}
          </div>
        </div>
        {isOpen && (
          <div className='accordion-item__content'>
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

AccordionItem.defaultProps = {
  labelTag: "span"
};

export default AccordionItem;
