import {Component} from 'react';

class StandardPageComponent extends Component {

	componentDidMount() {
		document.body.classList.remove('painted');
		document.body.classList.remove('mobile-transparent-header');
	}

}

export default StandardPageComponent;
